import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const ResearchAnalyst = () => {
  return (
    <div style={{ width: '80%', margin: '0 auto' }}>
      <Container fluid className="research-analyst-container py-5">
        <Row>
          <Col xs={12} className="text-center mb-4">
            <h1 className="display-4" style={{color:'grey'}}>Research Analyst</h1>
          </Col>
        </Row>

        {/* Personal Details Section */}
        <Row className="mb-5">
          <Col xs={12}>
            <h3 className="mb-4" style={{color:'grey'}}>Personal and Registration Details</h3>
            <p style={{fontSize:'25px' , color:'grey'}}>
              <strong>Name:</strong> Sameer Satish Mekde
            </p>
            <p style={{fontSize:'25px' , color:'grey'}}>
              <strong>Registration Number:</strong> INH000010946
            </p>
            <p style={{fontSize:'25px' , color:'grey'}}>
              <strong>Registered Address:</strong> G 2, Building No. 1, Prabhadevi C.H.S, Rajabhau Desai Marg, New Prabhadevi, Mumbai, Maharashtra, 400025
            </p>
            <p style={{fontSize:'25px' , color:'grey'}}>
              <strong>Brand Name:</strong> Trade Ace
            </p>
          </Col>
        </Row>

        {/* Risk Disclaimer */}
        <Row className="mb-5">
          <Col xs={12}>
            <h3 className="mb-4" style={{ color:'grey'}}>Important Disclaimer</h3>
            <p style={{fontSize:'25px', color:'grey'}}>
              <strong>“Investment in securities market are subject to market risks. Read all the related documents carefully before investing.”</strong>
            </p>
            <p style={{fontSize:'25px', color:'grey'}}>
              <strong>“Registration granted by SEBI, membership of BASL (in case of IAs) and certification from NISM in no way guarantee performance of the intermediary or provide any assurance of returns to investors.”</strong>
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ResearchAnalyst;
