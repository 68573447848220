import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import AddressCard from './AddressCard/AddressCard';
import ContactUsFormCard from './ContactUsFormCard/ContactUsFormCard';

const ContactUs = () => {
  return (
    <div className="contact-us-section">
      <Container>
        <Row className="gy-4">
          {/* Address Card */}
          <Col xs={12} md={6}>
            <AddressCard />
          </Col>

          {/* Contact Us Form */}
          <Col xs={12} md={6}>
            <ContactUsFormCard />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ContactUs;
